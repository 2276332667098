import { VApp } from 'vuetify/lib/components/VApp';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('div',{staticClass:"white-header"}),_c('Header'),_c(VMain,{staticClass:"mb-10"},[_c(VContainer,{staticClass:"portal-subheader"},[_c(VRow,[_c(VCol,{staticClass:"mb-2"},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('router-link',{staticClass:"d-flex align-center text-decoration-none mr-2 toolbar3-min-width",attrs:{"to":"/dashboard"}},[_c('h1',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$config.app_name))])]):_vm._e(),_c(VBreadcrumbs,{staticClass:"ma-0 pa-0 mb-3",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VBreadcrumbsItem,{attrs:{"to":item.to,"disabled":item.disabled}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(_vm.$t(item.text).toUpperCase())+" ")],1)]}},{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-arrow-right")])]},proxy:true}])})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{staticClass:"text-right",attrs:{"cols":"7"}},[_c('div',{staticClass:"d-none d-md-block d-lg-block"},_vm._l((_vm.userMenuItems),function(item,index){return _c(VBtn,{key:index,staticClass:"ml-1",attrs:{"to":item.link,"text":"","small":"","color":"primary","rounded":""}},[_c(VIcon,{attrs:{"small":"","left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")],1)}),1)]):_vm._e()],1),(!_vm.$vuetify.breakpoint.mdAndDown)?_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-5"},[_c('span',{staticClass:"text-h4",staticStyle:{"margin-left":"-2px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.title))+" ")])]),_c(VCol,{staticClass:"text-right"},[_c(VSpacer),(_vm.$config.show_cardholders)?_c('user-modal'):_vm._e()],1)],1):_vm._e(),_c('router-view',{staticClass:"mt-lg-10"})],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('foot'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }