<template>
  <div>
    <v-expansion-panels flat v-if="!loading" v-model="panel" accordion>
      <v-expansion-panel v-for="(service, i) in $lodash.sortBy(filterAvailableServices, 'name')" :key="i">
        <v-expansion-panel-header class="product-header">
          <strong>
            {{ service.name }}
          </strong>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-simple-table v-if="service.budgets.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("name") }}</th>
                    <th class="text-right">{{ $t("actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(budget, index) in $lodash.sortBy(
                    service.budgets,
                    'name'
                  )" :key="index">
                    <td class="px-3">{{ budget.name }}</td>
                    <td class="text-right px-3">
                      <v-btn v-if="!selectedProduct(budget)" small icon color="primary"
                        @click="showAddProductModal(service, budget)">
                        <v-icon>mdi-checkbox-blank-outline</v-icon>
                      </v-btn>
                      <v-btn v-else small icon color="success" @click="removeProduct(budget)">
                        <v-icon>mdi-checkbox-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-alert v-else outlined transition border="left" type="info" dense class="mx-3">
              {{ $t("noProductFound") }}
            </v-alert>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="loading" class="text-center">
      <div class="ma-10">
        <v-progress-circular indeterminate color="primary" :size="80" :width="2" class="mb-5"></v-progress-circular>
        <p>{{ $t("loadingProducts") }}</p>
      </div>
    </div>

    <v-dialog hide-overlay v-model="show" :fullscreen="$vuetify.breakpoint.mdAndDown" max-width="500">
      <v-card dark color="primary">
        <v-toolbar flat color="transparent">
          <strong class="mr-1">{{ $t("add") }}:</strong> {{ new_budget.name }}
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field outlined class="hide-arrows" :label="$t('amount')" v-model="new_budget.amount" type="number"
                :suffix="new_budget.unit" dense hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn x-large :loading="loading_add_product" block outlined @click="addProduct(new_budget)">
                {{ $t("add") }}
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["participant", "flat", "filter"],
  data() {
    return {
      show: false,
      available_services: [],
      loading: false,
      new_budget: {
        amount: null,
        unit: null,
      },
      panel: null,
      loading_add_product: false,
      products: [],
    };
  },
  created() {
    this.getAvailableServices();
  },
  methods: {
    selectedProduct(budget) {
      let found = this.products.find((product) => product.id == budget.id);

      if (found) {
        return true;
      }
      return false;
    },
    showAddProductModal(service, budget) {
      this.new_budget = budget;
      this.new_budget.amount = budget.default;
      this.new_budget.service_name = service.name;
      this.show = true;
    },
    async getAvailableServices() {
      this.loading = true;
      this.available_services = [];
      let cityid_programs = await this.getCityIDPograms();

      cityid_programs.forEach((program) => {
        this.available_services.push(program);
      });

      if (this.available_services) {
        var budgets = [];
        this.available_services.forEach((service) => {
          if (!service.budgets) {
            service.budgets = budgets;
          }
        });
      }


      return (this.loading = false);
    },
    async getCityIDPograms() {
      const response = await this.$http
        .get(this.$config.backend_url + "/provisioning/cityid")
        .then((response) => {
          let programs = [];
          response.data.provisionings.forEach((product) => {
            let new_product = {
              id: product.id,
              name: product.name,
              budgets: [],
            };

            let year = this.$config.mantelzorg_year ? this.$config.mantelzorg_year : new Date().getFullYear()

            product.budgets.forEach((budget) => {
              if (
                product.name == "Mantelzorgwaardering" &&
                budget.period == year.toString()
              ) {
                new_product.budgets.push({
                  id: budget.id,
                  name: budget.period,
                  default: budget.defaultAmount,
                  unit: budget.unit,
                  portal_module: "cityid",
                });
              } else if (product.name != "Mantelzorgwaardering") {
                new_product.budgets.push({
                  id: budget.id,
                  name: budget.period,
                  default: budget.defaultAmount,
                  unit: budget.unit,
                  portal_module: "cityid",
                });
              }
            });

            programs.push(new_product);
          });
          return programs;
        })
        .catch(() => {
          this.loading = false;
        });

      return response;
    },
    checkHasService(service_name) {
      let has_product = false;

      if (this.participant.services && this.participant.services.length > 0) {
        this.participant.services.forEach((service) => {
          if (service_name == service.name) {
            has_product = true;
          }
        });
      }

      return has_product;
    },
    addProduct(product) {
      this.products.push(product);
      this.show = false;
      this.$toast.success(this.$t("saved"));
    },
    removeProduct(product) {
      this.products = this.products.filter(
        (l_product) => l_product.id != product.id
      );
      this.$toast.success(this.$t("saved"));
    },
  },
  computed: {
    filterAvailableServices() {
      if (this.filter) {
        let services = this.available_services.filter(
          (service) => service.name == this.filter
        );
        return services;
      }

      return this.available_services;
    },
  },
  watch: {
    filterAvailableServices() {
      if (this.filterAvailableServices.length == 1) {
        this.panel = 0;
      } else {
        this.panel = null;
      }
    },
    products() {
      let products = [];
      this.products.forEach((product) => {
        let product_string =
          product.service_name +
          " " +
          product.name +
          " - aantal: " +
          product.amount +
          " (" +
          product.portal_module +
          ")";
        products.push(product_string);
      });

      this.$emit("refresh", products);
    },
  },
};
</script>

<style></style>
