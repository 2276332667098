import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-lg-5",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,[_vm._v(" Email template aanpassen ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.$t("Pas hier de tekst aan, let op: verwijder de html code niet"))+" ")]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveTranslation.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Key","readonly":""},model:{value:(_vm.translation.key),callback:function ($$v) {_vm.$set(_vm.translation, "key", $$v)},expression:"translation.key"}}),_c(VTextarea,{attrs:{"label":"Vertaling","outlined":"","rows":"12"},model:{value:(_vm.translation.value),callback:function ($$v) {_vm.$set(_vm.translation, "value", $$v)},expression:"translation.value"}}),_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }