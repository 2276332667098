import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"hide-overlay":"","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"600"},model:{value:(_vm.show_email_dialog),callback:function ($$v) {_vm.show_email_dialog=$$v},expression:"show_email_dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[_c('strong',[_vm._v(" Bevestigingsmail ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"confirm_submit_form"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.confirmation_email),callback:function ($$v) {_vm.confirmation_email=$$v},expression:"confirmation_email"}})],1)],1),_c(VFooter,{staticClass:"mt-5"},[_c(VBtn,{attrs:{"rounded":"","block":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.sendMail()}}},[_vm._v(" "+_vm._s(_vm.$t("saveAndSendEmail"))+" ")]),_c('strong',{staticClass:"mx-auto my-5"},[_vm._v("OF")]),_c(VBtn,{attrs:{"rounded":"","block":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Sluiten ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }