<template>
  <v-row>
    <Widget v-for="(widget, index) in widgets" :position="widget.widget_type.code" :key="index" :url="getUrl(widget)"
      :url_title="getUrlTitle(widget)" :title="getTitle(widget)" :html="getContent(widget)"
      :image_url="getImageUrl(widget)" :groups="groups" />
  </v-row>
</template>

<script>
import Widget from "../components/Widget.vue";

export default {
  components: {
    Widget,
  },
  name: "Dashboard",
  data() {
    return {
      widgets: [],
      groups: [],
    }
  },
  created() {
    this.getWidgets()
    this.getGroups()
  },
  methods: {
    getGroups() {
      this.loading = true;
      this.update_user_loading = true;
      this.$http
        .get(this.$config.backend_url + "/user/groups")
        .then((response) => {
          this.loading = false;
          this.groups = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getWidgets() {
      this.$http
        .get(this.$config.backend_url + "/widgets")
        .then((response) => {
          this.widgets = response.data
        })
        .catch((error) => {
          this.loading = false;
          this.error_response = "not_authorized";
          if (
            error.response.data.error &&
            error.response.data.error == "not_verified"
          ) {
            this.error_response = "not_verified";
          }
        });
    },
    getUrl(widget) {
      if (widget.widget_template) {
        return widget.widget_template.url
      }

      return null
    },
    getUrlTitle(widget) {
      if (widget.widget_template) {
        return widget.widget_template.url_title
      }

      return null
    },
    getTitle(widget) {
      if (widget.widget_template) {
        return widget.widget_template.name
      }

      return null
    },
    getContent(widget) {
      if (widget.widget_template) {
        return widget.widget_template.content
      }

      return null
    },
    getImageUrl(widget) {
      if (widget.widget_template) {
        return widget.widget_template.image_url
      }

      return null
    },
  },
  computed: {
  },
};
</script>
