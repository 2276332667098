<template>
  <v-card :rounded="$config.shaped_widgets" class="custom-widget">
    <v-img height="145px" :src="image_url">
    </v-img>
    <v-card-text class="custom-content">
      <span class="custom-widget-title">{{ title }}</span>
      <div class="content sc3">
        <div v-html="html"></div>
      </div>
    </v-card-text>
    <v-footer dark color="primary">
      <v-spacer></v-spacer>
      <!-- if url has http fill with _blank -->
      <v-btn text :target="getTarget(url)" :href="url" small>
        {{ url_title }}
        <v-icon right small>mdi-launch</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>


<script>
export default {
  props: ["url", "html", "title", "url_title", "image_url"],
  methods: {
    getTarget(url) {
      if (url.includes("http")) {
        return "_blank";
      }
      return "_self";
    },
  },
};
</script>

<style>
.custom-content {
  min-height: 122px;
  margin-left: 10px;
  max-width: fit-content;
}

.content {
  max-width: 95%;
  min-height: 122px;
  max-height: 122px;
  overflow-y: auto;
  padding-right: 15px;
  font-size: 9pt;
  line-height: 1.6;
}

.sc3::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.sc3::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
  border-radius: 10px;
}

.sc3::-webkit-scrollbar-thumb {
  background-color: rgba(83, 83, 83, 0.4);
  border-radius: 10px;
}

.custom-widget {
  height: 362px;
}

.custom-widget-title {
  font-size: 15px;
  font-weight: 700;
  position: relative;
  top: -5px;
  white-space: nowrap;
}
</style>
