<template>
  <v-app :style="getBackground">
    <v-app-bar dense flat app color="primary">
      <a style="text-decoration: none" :href="$config.return_url">
        <v-icon class="mr-1" small color="white">mdi-open-in-new</v-icon>
        <small class="white--text">
          {{ $t("backTo") }} {{ $config.name }}
        </small>
      </a>
      <v-spacer></v-spacer>
      <v-btn title="Contrast modus" dark icon @click="setContrastMode">
        <v-icon> mdi-contrast-circle </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fill-height fluid>
        <router-view />
      </v-container>
    </v-main>

    <Footer v-if="$vuetify.breakpoint.mdAndUp" />
  </v-app>
</template>

<script>
import Footer from "./Footer.vue";
export default {
  name: "Home",
  components: { Footer },
  data() {
    return {
      constrast_mode: false,
    };
  },
  methods: {
    setContrastMode() {
      this.contrast_mode = !this.contrast_mode;
      if (this.contrast_mode) {
        this.$vuetify.theme.themes.light.primary = "#000000";
        this.$vuetify.theme.themes.light.secondary = "#000000";
        this.$vuetify.theme.themes.light.accent = "#000000";
      } else {
        this.$vuetify.theme.themes.light.primary =
          this.$vuetify.theme.defaults.light.primary;
        this.$vuetify.theme.themes.light.secondary =
          this.$vuetify.theme.defaults.light.secondary;
        this.$vuetify.theme.themes.light.accent =
          this.$vuetify.theme.defaults.light.accent;
      }
    },
  },
  computed: {
    getBackground() {
      if (this.$config.background_image_url) {
        return (
          'background-image: url("' +
          this.$config.background_image_url +
          '");background-position: center center;background-size: cover'
        );
      }

      return (
        "background: linear-gradient(60deg," +
        this.$config.primary_color +
        " 50%," +
        this.$config.secondary_color +
        " 98%)"
      );
    },
  },
  watch: {
    constrast_mode: {
      handler() {
        this.$store.dispatch("setContrastMode", this.constrast_mode);
      },
    },
  },
};
</script>

<style lang="scss">
.Home {
  .v-app-bar {
    h2 {
      text-transform: uppercase;
    }
  }
}
</style>
