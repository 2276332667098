import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0"},[_vm._v(_vm._s(_vm.$t("contactInfo")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right",attrs:{"x-large":""}},[_vm._v("mdi-information-variant")])],1),_c(VCardText,{staticClass:"widget-height mx-4"},[(_vm.$config.phone_number)?[_c('h4',{staticClass:"my-3"},[_c(VIcon,{staticClass:"mr-3"},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.$config.phone_number)+" ")],1)]:_vm._e(),(_vm.$config.email)?[_c('a',{staticClass:"text-decoration-none primary--text",attrs:{"href":'mailto:' + _vm.$config.email}},[_c('h4',[_c(VIcon,{staticClass:"mr-3"},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.$config.email)+" ")],1)])]:_vm._e()],2),_c(VFooter,{staticClass:"pa-5"},[_c(VSpacer)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }