export default [
    {
        title: "dashboard",
        link: "/dashboard",
        icon: "mdi-view-dashboard",
        meta: "forMinima",
    },
    {
        title: "requests",
        link: "/requests",
        icon: "mdi-folder-multiple-outline",
    },
    // {
    //     title: "email templates",
    //     link: "/email-templates",
    //     icon: "mdi-email-multiple-outline",
    // },
    {
        title: "caregivers",
        link: "/caregivers",
        icon: "mdi-account-multiple",
        meta: "forMinima",
    },
    {
        title: "profile",
        link: "/profile",
        icon: "mdi-account",
        meta: "forMinima",
    },
];
