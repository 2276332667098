<template>
  <v-card :rounded="$config.shaped_widgets" :loading="loading" class="widget">
    <v-card-title class="mb-0" flat>
      <v-card-title class="mb-0 py-0">{{ $t("caregivers") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right" x-large>mdi-account-box-outline</v-icon>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="$store.getters.getParticipants"
        :items-per-page="4"
        sort-desc
        sort-by="time"
        hide-default-footer
        dense
        class="adjusted-margin"
      >
        <template v-slot:item.lastName="{ item }">
          <a @click="$router.push('/caregivers/' + item.id)">
            <small v-if="$vuetify.breakpoint.md"
              >{{ item.firstName }} {{ item.lastName }}</small
            >
            <small v-else>{{ item.firstName }} {{ item.lastName }}</small>
          </a>
        </template>

        <template v-slot:item.street="{ item }">
          <small> {{ item.street }} {{ item.houseNumber }}</small>
        </template>
      </v-data-table>
    </v-card-text>
    <v-footer color="#f1f1f1">
      <v-spacer></v-spacer>
      <v-btn text :to="'caregivers'" small>
        {{ $t("allCaregivers") }}
        <v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: "Caregivers",
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: this.$t("address"),
          align: "start",
          sortable: true,
          value: "street",
        },
      ],
    };
  },
  created() {
    this.getParticipants();
  },
  methods: {
    getParticipants() {
      this.loading = true;
      this.$http.get(this.$config.backend_url + "/users").then((response) => {
        this.participants = this.$lodash.sortBy(
          response.data.body.statusResults,
          "firstName"
        );
        this.$store.dispatch("setParticipants", this.participants);

        this.loading = false;
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
