<template>
  <div>
    <v-row>
      <v-col>
        <v-card v-if="has_permission" :rounded="$config.shaped_widgets" class="pa-lg-5">
          <v-card-title>
            <v-text-field rounded v-model="search" :label="$t('searchCaregivers')" prepend-inner-icon="mdi-magnify" filled
              dense clearable hide-details full-width></v-text-field>
            <v-spacer></v-spacer>
            <!-- <filter-modal @refresh="getParticipants" :options="filter_options" :return_array="true"></filter-modal> -->
          </v-card-title>
          <v-card-text>
            <v-data-table :loading="loading" :headers="headers" :items="filtered" sort-by="lastName"
              :options.sync="options" :items-per-page.sync="options.itemsPerPage" :footer-props="footer_props">
              <template v-slot:item.accessToken="{ item }"><a @click="$router.push('/caregivers/' + item.id)">{{
                item.accessToken
              }}</a>
              </template>

              <template v-slot:item.lastName="{ item }">
                {{ item.lastName }}, {{ item.firstName }}
              </template>

              <template v-slot:item.street="{ item }">
                {{ item.street }} {{ item.houseNumber }} {{ item.suffix }}
              </template>

              <template v-slot:item.externalId="{ item }">
                {{ item.externalId }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn :disabled="!item.id" @click="$router.push('/caregivers/' + item.id)" icon>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
                <v-btn :disabled="!item.id" @click="$router.push('/caregivers/edit/' + item.id)" icon>
                  <v-icon small>mdi-grease-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <!-- if no permission show message no permission -->

        <div v-else class="no-permission">
          <v-icon class="mb-5" size="100" color="grey">mdi-alert-circle-outline</v-icon>
          <p type="warning" outlined>{{ $t('U hebt geen toestemming om deze pagina te bekijken') }}</p>
        </div>


      </v-col>
    </v-row>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  name: "Participants",
  data() {
    return {
      loading: false,
      search: "",
      participants: [],
      filter_options: [
        {
          name: "lastName",
          label: this.$t("lastName"),
          type: "text-field",
        },
        {
          name: "street",
          label: this.$t("street"),
          type: "text-field",
        },
        {
          name: "zipCode",
          label: this.$t("postalCode"),
          type: "text-field",
        },
        {
          name: "accessToken",
          label: this.$t("accessToken"),
          type: "text-field",
        },

      ],
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: this.$t("card"),
          align: "start",
          sortable: true,
          value: "accessToken",
        },
        {
          text: this.$t("address"),
          align: "start",
          sortable: true,
          value: "street",
        },
        {
          text: this.$t("externalId"),
          align: "start",
          sortable: true,
          value: "externalId",
        },
        {
          text: this.$t("zipCode"),
          align: "start",
          sortable: true,
          value: "zipCode",
        },
        {
          text: this.$t("actions"),
          align: "end",
          sortable: true,
          value: "actions",
        },
      ],
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: true,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [],
      },
      footer_props: {
        itemsPerPageOptions: [5, 15, 25, 50, 100, 150],
        showFirstLastPage: true,
      },
      has_permission: false,
    };
  },
  created() {
    this.checkPermission();
  },
  methods: {
    checkPermission() {
      this.has_permission = this.$config.show_cardholders;

      if (this.has_permission) {
        this.getParticipants();
      }
    },
    getParticipants() {
      this.loading = true;
      this.$http
        .get(this.$config.backend_url + "/users")
        .then((response) => {
          this.participants = response.data.body.statusResults;
          this.$store.dispatch("setParticipants", this.participants);
          this.loading = false;
        });
    },
  },
  computed: {
    filtered() {
      const options = {
        includeScore: true,
        keys: ['first_name', 'lastName', 'accessToken', 'street', 'externalId', 'zipCode', 'houseNumber', 'suffix', 'email'],
        threshold: 0.3,
      }

      const fuse = new Fuse(this.participants, options)

      let result = []

      if (this.search) {
        fuse.search(this.search).forEach(item => {
          result.push(item.item)
        })
      } else {
        result = this.participants
      }

      return result
    }
  }
};
</script>

<style lang="scss">
</style>
