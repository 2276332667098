<template>
  <v-card :loading="loading" :rounded="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0">{{ $t("budgets") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right" x-large>mdi-currency-eur</v-icon>
    </v-card-title>

    <v-card-text>
      <v-data-table :headers="headers" :items="budgets" sort-desc sort-by="amount" hide-default-footer dense
        class="adjusted-margin" calculate-widths>
        <template v-slot:item.name="{ item }">
          <small>{{ $t(item.name) }}</small>
        </template>

        <template v-slot:item.budget="{ item }">
          <small>{{ item.budget | currency }}</small>
        </template>
      </v-data-table>
    </v-card-text>
    <v-footer class="pa-5"> </v-footer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: this.$t("name"),
          sortable: true,
          value: "name",
          align: "start",
        },
        {
          text: this.$t("budget"),
          align: "end",
          sortable: true,
          value: "budget",
        },
      ],
      budgets: [],
      loading: false,
    };
  },
  created() {
    this.getBudgets();
  },
  methods: {
    getBudgets() {
      this.loading = true;
      this.$http
        .get(
          this.$config.backend_url +
          "/budgets"
        )
        .then((response) => {
          this.budgets = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calcPercentage(item) {
      let percentage = (100 * parseInt(item.issued)) / parseInt(item.budget);
      return Math.round(percentage);
    },
  },
};
</script>

<style></style>
