import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"loading":_vm.loading,"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0"},[_vm._v(_vm._s(_vm.$t("budgets")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right",attrs:{"x-large":""}},[_vm._v("mdi-currency-eur")])],1),_c(VCardText,[_c(VDataTable,{staticClass:"adjusted-margin",attrs:{"headers":_vm.headers,"items":_vm.budgets,"sort-desc":"","sort-by":"amount","hide-default-footer":"","dense":"","calculate-widths":""},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('small',[_vm._v(_vm._s(_vm.$t(item.name)))])]}},{key:"item.budget",fn:function({ item }){return [_c('small',[_vm._v(_vm._s(_vm._f("currency")(item.budget)))])]}}])})],1),_c(VFooter,{staticClass:"pa-5"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }