<template>
    <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
        <v-card-title>{{ $t("products") }}

            <v-spacer></v-spacer>
            <add-products-modal @refresh="getProducts()" :participant="participant" :outlined="true" :small="true"
                color="primary" icon="mdi-plus" button_text="addProduct"></add-products-modal>
        </v-card-title>
        <v-card-text>
            <v-data-table :loading="loading" :headers="service_headers" :items="participant_services" sort-by="name"
                item-key="uuid">
                <template v-slot:item.name="{ item }">{{ parseProductName(item.name) }} </template>

                <template v-slot:item.balance="{ item }">
                    <span v-if="item.unit == 'EURO'">{{ item.balance | currency }}</span>
                    <span v-else>{{ item.balance }}</span>
                </template>

                <template v-slot:item.unit="{ item }">
                    {{ $t(item.unit) }}
                </template>

                <template v-slot:item.start_date="{ item }">
                    {{ formatDate(item.start_date) }}
                </template>

                <template v-slot:item.end_date="{ item }">
                    {{ formatDate(item.end_date) }}
                </template>

                <template v-slot:item.count="{ item }">
                    {{ item.count }}
                </template>

                <template v-slot:item.updated_at="{ item }">
                    {{ formatDate(item.updated_at) }}
                </template>

                <template v-slot:item.actions="{ item }">
                    <add-budget-modal :budget_id="item.id" :accessToken="participant.accessToken"
                        @refresh="getProducts()"></add-budget-modal>

                    <v-btn @click="deleteBudget(item)" icon>
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

        </v-card-text>
    </v-card>
</template>

<script>
import AddProductsModal from "../../../components/AddProductsModalV2.vue";
import AddBudgetModal from "../../../components/AddBudgetModal.vue";
import moment from "moment";

export default {
    components: {
        AddProductsModal,
        AddBudgetModal,
    },
    name: "Products",
    props: ["participant"],
    data() {
        return {
            loading: false,
            show: false,
            participant_services: [],
            service_headers: [
                {
                    text: this.$t("name"),
                    align: "start",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t("balance"),
                    align: "start",
                    sortable: true,
                    value: "balance",
                },
                {
                    text: this.$t("unit"),
                    align: "start",
                    sortable: true,
                    value: "unit",
                },
                {
                    text: this.$t("startDate"),
                    align: "start",
                    sortable: true,
                    value: "start_date",
                },
                {
                    text: this.$t("endDate"),
                    align: "start",
                    sortable: true,
                    value: "end_date",
                },
                {
                    text: this.$t("Aantal"),
                    align: "start",
                    sortable: true,
                    value: "count",
                },
                {
                    text: this.$t("Laatst toegewezen"),
                    align: "start",
                    sortable: true,
                    value: "updated_at",
                },
                {
                    text: this.$t("actions"),
                    align: "end",
                    sortable: true,
                    value: "actions",
                },
            ],
            available_services: [],
        };
    },
    created() {
        this.getProducts();
    },
    methods: {
        parseProductName(name) {
            return name.replace(/_/g, " ");
        },
        generateGuid() {
            var result, i, j;
            result = "";
            for (j = 0; j < 32; j++) {
                if (j == 8 || j == 12 || j == 16 || j == 20) result = result + "-";
                i = Math.floor(Math.random() * 16)
                    .toString(16)
                    .toUpperCase();
                result = result + i;
            }
            return result;
        },
        async getProducts() {
            this.loading = true;
            let cityid_budgets = await this.getCityIdWallets();

            let all_budgets = [];

            cityid_budgets.forEach((budget) => {
                all_budgets.push(budget);
            });

            this.participant_services = all_budgets;

            this.loading = false;
        },
        async getCityIdBudgets() {
            const response = await this.$http
                .get(
                    this.$config.backend_url +
                    "/provisioning/cityid/" +
                    this.participant.accessToken
                )
                .then((response) => {
                    let budgets = [];
                    response.data.forEach((budget) => {
                        budgets.push({
                            uuid: this.generateGuid(),
                            id: budget.id,
                            name: budget.period,
                            start_date: budget.startDate,
                            end_date: budget.endDate,
                            unit: "EURO",
                            balance: "",
                            portal_module: "cityid",
                        });
                    });
                    return budgets;
                })
                .catch(() => {
                    this.loading = false;
                });

            return response;
        },
        async getCityIdWallets() {
            const response = await this.$http
                .get(
                    this.$config.backend_url +
                    "/portalcards/" +
                    this.participant.accessToken
                )
                .then((response) => {
                    console.log(response.data);
                    let budgets = [];
                    response.data.forEach((budget) => {
                        budgets.push({
                            id: budget.budgetId,
                            name: budget.name,
                            start_date: budget.validityStart,
                            end_date: budget.validityEnd,
                            unit: budget.unit,
                            balance: budget.currentAmount,
                            portal_module: "cityid",
                            count: budget.count,
                            updated_at: budget.updated_at,
                        });
                    });
                    return budgets;
                })
                .catch(() => {
                    this.loading = false;
                });

            return response;
        },
        async addBudget(budget) {
            console.log(budget)
            this.$refs.add_budget_modal.openModal(budget.id)

        },
        deleteBudget(budget) {
            this.$confirm(this.$t("confirmDelete")).then((res) => {
                if (res) {
                    this.loading = true;
                    var endpoint = null;
                    var body = null;

                    if (budget.portal_module == "cityid") {
                        endpoint = "/provisioning/cityid/delete";

                        body = {
                            accesstoken: this.participant.accessToken,
                            budget_id: budget.id,
                        };
                    }

                    if (budget.portal_module == "groupit") {
                        endpoint = "/provisioning/groupit/delete";
                        body = {
                            accesstoken_code: this.participant.accessToken,
                            programbudget_balancedefinition: budget.id,
                            amount: budget.balance,
                        };
                    }

                    this.$http
                        .post(this.$config.backend_url + endpoint, body)
                        .then(() => {
                            this.getProducts();
                            this.$toast.success(this.$t("successfullyDeleted"));
                            this.loading = false;
                        })
                        .catch(() => {
                            this.$toast.error(this.$t("somethingWentWrong"));
                            this.loading = false;
                        });
                }
            });
        },
        formatDate(date) {
            if (moment(date).isValid()) {
                return moment(date).format("DD-MM-YYYY");
            }

            return "-";
        },
    },
    watch: {
        participant() {
            this.getProducts();
        },
    },
};
</script>

<style></style>
