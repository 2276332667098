<template>
  <div>
    <v-btn outlined small rounded color="primary" @click="show = true">
      <v-icon small left>mdi-account-plus</v-icon>
      {{ $t("addCaregiver") }}
    </v-btn>

    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown" v-model="show" max-width="800" scrollable>
      <v-card>
        <v-toolbar flat>
          <strong>
            {{ $t("addCaregiver") }}
          </strong>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="cardholder_form">
            <v-stepper class="mt-5" flat v-model="step" vertical>
              <v-stepper-step :rules="[() => valid]" editable :complete="step > 1" step="1">
                {{ $t("cardholder") }}
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card>
                  <v-card-text>
                    <v-text-field v-model="cardholder.prefix" :label="$t('prefix')"></v-text-field>
                    <v-text-field v-model="cardholder.first_name" :label="$t('firstName')" :rules="[required]"
                      validate-on-blur></v-text-field>
                    <v-text-field v-model="cardholder.preposition" :label="$t('preposition')"></v-text-field>
                    <v-text-field v-model="cardholder.last_name" :label="$t('lastName')" :rules="[required]"
                      validate-on-blur></v-text-field>

                    <date-picker v-model="cardholder.date_of_birth" :label="$t('dateOfBirth')" />

                    <v-text-field v-model="cardholder.phonenumber" :label="$t('phoneNumber')" :rules="[required]"
                      v-mask="'##########'" validate-on-blur></v-text-field>
                    <v-text-field v-model="cardholder.email" :label="$t('email')" :rules="emailRules"
                      validate-on-blur></v-text-field>

                    <address-lookup :address="cardholder"></address-lookup>
                  </v-card-text>
                  <v-footer>
                    <v-btn block rounded class="mt-5" color="primary" @click="step = 2">
                      {{ $t("next") }}
                      <v-icon right>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-footer>
                </v-card>
              </v-stepper-content>

              <v-stepper-step editable step="2">
                {{ $t("addProducts") }}</v-stepper-step>
              <v-stepper-content step="2">

                <add-products-array :participant="cardholder" @refresh="setProducts"></add-products-array>

                <v-footer class="mt-5">
                  <v-btn :loading="loading" rounded block color="primary" @click="submitRequest()">
                    {{ $t("save") }}
                  </v-btn>
                </v-footer>

              </v-stepper-content>
            </v-stepper>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddProductsArray from "./AddProductsArray.vue";
import AddressLookup from "./AddressLookup.vue";
export default {
  components: { AddProductsArray, AddressLookup },
  data() {
    return {
      valid: true,
      loading: false,
      show: false,
      step: 1,
      error: false,
      required: (value) => !!value || this.$t("mandatory"),
      emailRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) ||
          this.$t("validEmail"),
      ],
      cardholder: {
        prefix: "",
        first_name: "",
        preposition: "",
        last_name: "",
        date_of_birth: "",
        phonenumber: "",
        email: "",
        postal_code: "",
        housenumber: "",
        housenumber_addition: "",
        street: "",
        city: "",
        products: [],
      },
    };
  },
  created() { },
  methods: {
    setProducts(products) {
      this.cardholder.products = products;
    },
    submitRequest() {
      if (!this.$refs.cardholder_form.validate()) {
        this.valid = false;
        return this.$toast.error(this.$t("notAllFieldsWereFilled"));
      }

      this.valid = true;
      this.loading = true;

      this.cardholder.products = this.cardholder.products.join(", ");

      this.$http
       .post(this.$config.backend_url + "/cardholders", this.cardholder)
        .then(() => {
          this.loading = false;
          this.$refs.cardholder_form.reset();
          this.$toast.success(this.$t("saved"));
          this.show = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error(this.$t("somethingWentWrong"));
        });
    },
    getParticipants(id) {
      this.$http
        .get(this.$config.backend_url + "/users")
        .then((response) => {
          this.$store.dispatch("setParticipants", response.data.body.statusResults);
          this.$router.push('/caregivers/' + id)
          this.loading = false;
          this.show = false
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style>
.center {
  width: 100%;
  text-align: center;
}

canvas {
  display: inline;
}
</style>
