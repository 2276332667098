import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContent,[_c(VToolbar,{staticClass:"wide-toolbar mb-3",attrs:{"color":"transparent","flat":""}},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-content-save")])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-lg-5",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,[_vm._v("Gegevens "+_vm._s(_vm.participant.name)+" "),_c(VSpacer)],1),_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$t('firstName')},model:{value:(_vm.participant.firstName),callback:function ($$v) {_vm.$set(_vm.participant, "firstName", $$v)},expression:"participant.firstName"}}),_c(VTextField,{attrs:{"label":_vm.$t('lastName')},model:{value:(_vm.participant.lastName),callback:function ($$v) {_vm.$set(_vm.participant, "lastName", $$v)},expression:"participant.lastName"}}),_c('address-lookup',{attrs:{"address":{
              postal_code: _vm.participant.zipCode,
              housenumber: _vm.participant.houseNumber,
              housenumber_addition: _vm.participant.suffix,
              street: _vm.participant.street,
              city: _vm.participant.city,
            }},on:{"refresh":_vm.setAddress}}),_c(VTextField,{attrs:{"label":_vm.$t('email'),"rules":_vm.emailRules},model:{value:(_vm.participant.email),callback:function ($$v) {_vm.$set(_vm.participant, "email", $$v)},expression:"participant.email"}}),_c(VTextField,{attrs:{"label":_vm.$t('card'),"disabled":""},model:{value:(_vm.participant.accessToken),callback:function ($$v) {_vm.$set(_vm.participant, "accessToken", $$v)},expression:"participant.accessToken"}})],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c('products',{attrs:{"participant":_vm.participant}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }