import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContent,[_c(VToolbar,{staticClass:"wide-toolbar mb-3",attrs:{"color":"transparent","flat":""}},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/caregivers/edit/' + _vm.participant.id)}}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-grease-pencil")])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-lg-5",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,[_vm._v("Gegevens "),_c(VSpacer)],1),_c(VCardText,[_c('field-row',{attrs:{"label":_vm.$t('firstName'),"value":_vm.participant.firstName}}),_c('field-row',{attrs:{"label":_vm.$t('lastName'),"value":_vm.participant.lastName}}),_c('field-row',{attrs:{"label":_vm.$t('address'),"value":_vm.address}}),_c('field-row',{attrs:{"label":_vm.$t('postalCodeAndCity'),"value":_vm.postalCode.toUpperCase()}}),_c('field-row',{attrs:{"label":_vm.$t('card'),"value":_vm.participant.accessToken}})],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c('products',{attrs:{"participant":_vm.participant}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }