import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"custom-widget",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VImg,{attrs:{"height":"145px","src":_vm.image_url}}),_c(VCardText,{staticClass:"custom-content"},[_c('span',{staticClass:"custom-widget-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"content sc3"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.html)}})])]),_c(VFooter,{attrs:{"dark":"","color":"primary"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","target":_vm.getTarget(_vm.url),"href":_vm.url,"small":""}},[_vm._v(" "+_vm._s(_vm.url_title)+" "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-launch")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }