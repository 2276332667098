<template>
  <v-col lg="4" sm="6" xs="12" md="6" cols="12">
    <Cardholders v-if="showWidget('Cardholders')" />
    <Requests v-if="showWidget('Requests')" />
    <Stats v-if="showWidget('Stats')" />
    <Profile v-if="showWidget('Profile')" />
    <Info v-if="showWidget('Info')" />
    <Budgets v-if="showWidget('Budgets')" />
    <Custom v-if="showWidget('Custom')" :url="url" :url_title="url_title" :title="title" :html="html"
      :image_url="image_url" />
    <ValidationCheck v-if="showWidget('ValidationCheck')" />
  </v-col>
</template>

<script>
import Stats from "./widgets/Stats.vue";
import Cardholders from "./widgets/Cardholders.vue";
import Requests from "./widgets/Requests.vue";
import Profile from "./widgets/Profile.vue";
import Info from "./widgets/Info.vue";
import Budgets from "./widgets/Budgets.vue";
import Custom from "./widgets/Custom.vue";
import ValidationCheck from "./widgets/ValidationCheck.vue";

export default {
  props: ["position", "widget_name", "title", "url", "url_title", "html", "image_url", "groups"],
  components: {
    Cardholders,
    Requests,
    Stats,
    Profile,
    Info,
    Budgets,
    Custom,
    ValidationCheck
  },
  methods: {
    showWidget(widget_name) {
      if (this.position == widget_name && this.canShow(widget_name)) {
        return true;
      }

      return false;
    },
    canShow(widget_name) {
      if (this.groups.indexOf("MANTELZORG_PORTAL") >= 0) {
        let dashboard_widgets = ['Budgets', 'Stats', 'Requests', 'Cardholders', 'Profile', 'Info', 'Custom', 'ValidationCheck']
        // check if widget_name is in dashboard_widgets
        return dashboard_widgets.indexOf(widget_name) >= 0;
      }
      if (this.groups.indexOf("MINIMA_PORTAL") >= 0) {
        let minima_dashboard_widgets = ['Cardholders', 'Profile', 'Info', 'Custom']
        // check if widget_name is in minima_dashboard_widgets
        return minima_dashboard_widgets.indexOf(widget_name) >= 0;
      }
    }
  },
};
</script>

<style></style>
