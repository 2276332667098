<template>
    <div style="display: unset;">
        <v-btn @click="show = true" text color="primary" icon>
            <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-dialog scrollable v-model="show" width="400px">
            <v-card>
                <v-toolbar flat color="white">{{ $t("addBudget") }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field type="number" v-model="budget_amount" label="Hoeveelheid" outlined></v-text-field>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="show = false" text>{{ $t("cancel") }}</v-btn>
                        <v-btn :loading="loading" @click="save()" color="primary">{{ $t("save") }}</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['accessToken', 'budget_id'],
    data() {
        return {
            show: false,
            loading: false,
            budget_amount: null,
        };
    },
    created() {
        this.getBudget();
    },
    methods: {
        refresh() {
            this.$emit("refresh");
        },
        async getBudget() {
            this.$store.getters.getPrograms.provisionings.forEach((program) => {
                program.budgets.forEach((budget) => {
                    if (budget.id === this.budget_id) {
                        this.budget_amount = budget.defaultAmount;
                    }
                });
            });
        },
        async save() {
            this.loading = true;

            // Validate input
            if (!this.budget_amount) {
                this.$toast.error(this.$t("fillAllFields"));
                this.loading = false;
                return;
            }

            try {
                const response = await this.$http.post(`${this.$config.backend_url}/provisioning/cityid/add-amount`, {
                    amount: this.budget_amount,
                    budget_id: this.budget_id,
                    accesstoken: this.accessToken,
                });

                // Handle success
                if (response.status === 201 || response.status === 200) {
                    this.$emit("refresh");
                    this.show = false;
                    this.$toast.success(this.$t("budgetUpdated"));
                    return;
                }

                // Handle unexpected success response
                this.$toast.error(this.$t("unexpectedError"));

            } catch (error) {
                // Centralized error handling
                const errorMessage = error.response?.data?.detail || this.$t("errorOccurred");

                // Specific handling based on status code
                if (error.response) {
                    const statusCode = error.response.status;

                    if (statusCode >= 400 && statusCode < 500) {
                        this.$toast.error(this.$t(errorMessage) || this.$t("clientError"));
                    } else if (statusCode >= 500) {
                        this.$toast.error(this.$t(errorMessage) || this.$t("serverError"));
                    } else {
                        this.$toast.error(this.$t("unexpectedError"));
                    }
                } else if (error.request) {
                    // Request made but no response received
                    this.$toast.error(this.$t("noResponse"));
                } else {
                    // Error in setting up the request
                    this.$toast.error(this.$t("requestError"));
                }
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<style></style>
