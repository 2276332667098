<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pa-lg-5" flat>
        <!-- on large screens it should be vertical -->
        <v-tabs v-model="tab" :vertical="$vuetify.breakpoint.mdAndUp">
          <v-tab href="#profile">{{ $t('accountInfo') }}</v-tab>
          <v-tab href="#password-change">{{ $t('passwordChange') }}</v-tab>
          <v-tab href="#2fa">{{ $t('Tweestapsverificatie') }}</v-tab>
          <v-tab href="#settings">{{ $t('Instellingen') }}</v-tab>

          <v-tabs-items v-model="tab" class="pl-lg-3 pl-md-3">
            <v-tab-item value="profile">

              <v-card-title>{{ $t("accountInfo") }}</v-card-title>
              <v-card-text>
                <v-form v-model="update_user_valid" ref="update_user">
                  <v-alert v-if="update_user_success" outlined transition type="success" border="left" color="success">
                    {{ $t("saved") }}
                  </v-alert>
                  <v-alert v-if="update_user_error" outlined transition type="error" border="left" color="error">
                    {{ $t("error") }}
                  </v-alert>
                  <v-alert v-if="hasTranslation('changeProfileInfo')" type="info" border="left" color="info" outlined>
                    {{ $t("changeProfileInfo") }}
                  </v-alert>


                  <v-row>
                    <v-col md="12" sm="12" xs="12" lg="4" cols="12">
                      <v-text-field :label="$t('firstName')" v-model="change_user.firstname" :rules="required"
                        :disabled="update_user_loading"></v-text-field>
                    </v-col>
                    <v-col md="12" sm="12" xs="12" lg="4" cols="12">
                      <v-text-field :label="$t('preposition')" v-model="change_user.preposition"
                        :disabled="update_user_loading"></v-text-field>
                    </v-col>
                    <v-col md="12" sm="12" xs="12" lg="4" cols="12">
                      <v-text-field :label="$t('lastName')" v-model="change_user.lastname" :rules="required"
                        :disabled="update_user_loading"></v-text-field>
                    </v-col>
                  </v-row>


                  <v-text-field :label="$t('emailAddress')" v-model="change_user.email" :rules="emailRules"
                    :disabled="update_user_loading"></v-text-field>

                  <v-text-field :label="$t('username')" v-model="change_user.username" disabled aria-disabled
                    :hint="$t('changeUsernameNotPossible')" persistent-hint></v-text-field>
                </v-form>

                <div v-if="user.profile_info">
                  <v-card-title class="pa-0 mt-5">{{ $t("Overige informatie") }}</v-card-title>
                  <v-divider class="mb-2"></v-divider>

                  <v-list>
                    <v-list-item class="pl-0">
                      <v-list-item-content>
                        <v-list-item-title>Postcode</v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>{{ user.postalCode ? user.postalCode : 'Geen postcode bekend' }}</h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pl-0">
                      <v-list-item-content>
                        <v-list-item-title>Huisnummer</v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>{{ user.houseNumber ? user.houseNumber : 'Geen huisnummer bekend'
                            }}</h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pl-0">
                      <v-list-item-content>
                        <v-list-item-title>Straat</v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>{{ user.street ? user.street : 'Geen straat bekend'
                            }}</h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pl-0">
                      <v-list-item-content>
                        <v-list-item-title>Geboortedatum</v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>{{ user.birthdate ? parsedBirtdate(user.birthdate) : 'Niet opgegeven'
                            }}</h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>


              </v-card-text>
              <v-footer rounded>
                <v-spacer></v-spacer>
                <v-btn text :loading="update_user_loading" color="primary" @click="updateUser()">{{ $t("save") }}
                  <v-icon right small>mdi-content-save</v-icon>
                </v-btn>
              </v-footer>

            </v-tab-item>


            <v-tab-item value="password-change">
              <v-card-title>{{ $t("passwordChange") }}</v-card-title>
              <v-card-text class="pt-0 mt-0">
                <v-form ref="password_update" v-model="valid">
                  <v-alert v-if="success" outlined transition type="success" border="left" color="success">
                    {{ $t("passwordUpdated") }}
                  </v-alert>
                  <v-alert v-if="error" outlined transition type="error" border="left" color="error">
                    {{ $t("passwordIncorrect") }}
                  </v-alert>

                  <password :label="$t('currentPassword')" v-model="reset_password.password" :strength_meter="true">
                  </password>

                  <password :label="$t('newPassword')" v-model="reset_password.new_password"></password>

                  <v-text-field :label="$t('confirmNewPassword')" :type="show_password ? 'text' : 'password'"
                    v-model="reset_password.new_password_confirmation" :rules="confirmPasswordRules"
                    :append-icon="!show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_password = !show_password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-footer>
                <v-btn text @click="$refs.password_update.reset()">
                  <v-icon left>mdi-refresh</v-icon>
                  {{ $t("reset") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text :loading="loading" @click="resetPassword()" color="primary">{{ $t("save") }}
                  <v-icon right small>mdi-content-save</v-icon>
                </v-btn>
              </v-footer>

            </v-tab-item>

            <v-tab-item value="2fa">
              <v-card-title>{{ $t("Tweestapsverificatie (2FA)") }}
              </v-card-title>
              <!-- subtitle -->
              <v-card-subtitle>
                {{ intro }}</v-card-subtitle>
              <v-card-text>

                <div v-if="!mfa_enabled">
                  <MFAConfiguration></MFAConfiguration>
                </div>

                <div class="mfa-enabled" v-else>
                  <v-icon size="200" color="success">mdi-check-circle-outline</v-icon>
                  <strong>{{ $t("You have enabled two factor authentication!") }}</strong>
                </div>

              </v-card-text>

            </v-tab-item>

            <v-tab-item value="settings">
              <v-card-title>{{ $t("Instellingen") }}
              </v-card-title>
              <v-card-text>
                <v-switch hide-details v-model="contrast_mode">
                  <template v-slot:label>
                    <span>{{ $t("contrastMode") }}</span>
                  </template>
                </v-switch>

                <v-radio-group :label="$t('chooseLanguage')" v-model="language">
                  <v-radio value="nl" :label="$t('dutch')"> </v-radio>
                  <v-radio value="en" :label="$t('english')"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-footer rounded>
                <v-spacer></v-spacer>
                <v-btn text :loading="update_user_loading" color="primary" @click="updateUser()">{{ $t("save") }}
                  <v-icon right small>mdi-content-save</v-icon>
                </v-btn>
              </v-footer>

            </v-tab-item>
          </v-tabs-items>

        </v-tabs>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MFAConfiguration from "../components/MFAConfiguration.vue";
import Password from "../components/Password.vue";
export default {
  components: { Password, MFAConfiguration },
  name: "Profile",
  data() {
    return {
      intro: this.$t('Voeg een extra beveiligingslaag toe om ongeautoriseerde toegang te blokkeren en je account te beschermen'),
      mfa_code: "",
      mfa_enabled: false,
      tab: 'profile',
      language: "nl",
      contrast_mode: false,
      loading: false,
      update_user_valid: false,
      valid: false,
      show_password: false,
      reset_password: {
        password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      change_user: {
        email: "",
        firstname: "",
        lastname: "",
        preposition: "",
        username: "",
      },
      emailRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) ||
          this.$t("validEmail"),
      ],
      required: [(v) => !!v || this.$t("mandatory")],
      confirmPasswordRules: [
        (value) => !!value || this.$t("confirmPassword"),
        (value) =>
          value === this.reset_password.new_password || this.$t("noMatch"),
      ],
      success: false,
      error: false,
      update_user_success: false,
      update_user_error: false,
      update_user_loading: false,
      secret_code: null,
      qr_code: null,
      user: {
        first_name: "",
        preposition: "",
        last_name: "",
        email: "",
        username: "",
      },
    };
  },
  created() {
    this.getUser();
    this.contrast_mode = this.$store.getters.contrastMode;
    this.language = this.$store.getters.language;
  },
  methods: {
    getUser() {
      this.update_user_loading = true;
      this.$http
        .get(this.$config.backend_url + "/user")
        .then((response) => {
          this.user = response.data;
          this.$store.dispatch("setUser", response.data);
          let user = this.$store.getters.getUser;
          this.change_user.firstname = user.first_name;
          this.change_user.preposition = user.preposition;
          this.change_user.lastname = user.last_name;
          this.change_user.email = user.email;
          this.change_user.username = user.username;
          this.update_user_loading = false;
        })
        .catch((error) => {
          this.update_user_loading = false;
          this.error = true;
          this.error_response = error;
        });
    },
    updateUser() {
      this.update_user_loading = true;
      if (this.$refs.update_user.validate())
        this.$http
          .post(this.$config.backend_url + "/user/update", this.change_user)
          .then(() => {
            this.update_user_loading = false;
            this.update_user_success = true;
            this.update_user_error = false;
            this.getUser();
          })
          .catch(() => {
            this.update_user_loading = false;
            this.update_user_error = true;
            this.update_user_success = false;
          });
    },
    resetPassword() {
      this.success = false;
      this.error = false;

      if (this.$refs.password_update.validate())
        this.$http
          .post(
            this.$config.backend_url + "/user/change-password",
            this.reset_password
          )
          .then(() => {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.$refs.password_update.reset();
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
            this.success = false;
          });
    },
    hasTranslation(key) {
      return this.$t(key) !== key;
    }
  },
  computed: {
    parsedBirtdate() {
      return (date) => {
        if (date) {
          return new Date(date).toLocaleDateString();
        }
      };
    },
    parseCode() {
      // we make the this.secret_code more readable
      return (code) => {
        if (code) {
          return code.match(/.{1,4}/g).join(" ");
        }
      };
    },
    showUsername() {
      if (this.change_user.username == this.change_user.email) {
        return false;
      }

      return true;
    },
  },
  watch: {
    contrast_mode: {
      handler() {
        this.$store.dispatch("setContrastMode", {
          contrast_mode: this.contrast_mode,
          currentVueComponent: this,
        });
      },
    },
    "$store.getters.contrastMode": {
      handler() {
        this.contrast_mode = this.$store.getters.contrastMode;
      },
    },
    language: {
      handler() {
        this.$store.dispatch("setLanguage", {
          language: this.language,
          currentVueComponent: this,
        });
      },
    },
    "$store.getters.language": {
      handler() {
        this.language = this.$store.getters.language;
      },
    },
  },
};
</script>

<style lang="scss">
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
}
</style>
