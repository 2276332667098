<template>
  <div>
    <v-btn :outlined="outlined" :small="small" rounded :color="color" @click="show = true">
      {{ $t(button_text) }}
      <v-icon right>{{ icon }}</v-icon>
    </v-btn>

    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown" v-model="show" max-width="800" scrollable>
      <v-card>
        <v-toolbar flat color="transparent">
          <strong>
            Producten toevoegen voor {{ participant.lastName }}
          </strong>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="cardholder_form">

            <add-products @addedProduct="product_added = true" :participant="participant" @refresh="refresh"
              flat="flat"></add-products>

          </v-form>

          <!-- dialog for sending email -->

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AddProducts from "./AddProductsComp.vue";
export default {
  components: { AddProducts },
  props: [
    "participant",
    "filter",
    "outlined",
    "small",
    "color",
    "icon",
    "button_text",
  ],
  data() {
    return {
      show_email_dialog: true,
      product_added: false,
      show: false,
      loading: false,
      valid: true,
      step: 1,
      confirmation_email: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  created() {
    this.confirmation_email = this.$t("addedProductsEmailBody").replace(
      /<div/g,
      "<p"
    );
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    sendMail() {
      let email = {
        email: this.participant.email,
        body: this.confirmation_email,
        subject: this.$t("addedProductsEmailSubject"),
      };
      this.$http
        .post(this.$config.backend_url + "/care-request-mail", email)
        .then(() => {
          this.loading = false;
          this.show = false;
          this.$toast.success(this.$t("saved"));
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error(this.$t("somethingWentWrong"));
        });
    },
  },
  computed: {
    canShowEmailTemplate() {
      const care_budget_id = this.$config.care_budget_id;

      // we should check if the selected care budget is the same as the care budget of the participant
      return this.participant.care_budget_id === care_budget_id;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.participant.confirmation_remark = this.$t(
          "approvalRemarkApprovedText"
        ).replace(/<div/g, "<p");
        if (this.$refs.add_products) {
          this.$refs.add_products.getAvailableServices();
        }

      }
    },
  },
};
</script>

<style></style>
