import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":"","rounded":_vm.$config.shaped_widgets},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v("mdi-filter")]),_vm._v(" "+_vm._s(_vm.$t("filter"))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.filter = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mt-0 pt-0"},[_vm._l((_vm.data),function(field,index){return [(field.type == 'boolean')?_c(VSwitch,{key:index,attrs:{"label":field.label},on:{"change":function($event){return _vm.emitString()}},model:{value:(field.string),callback:function ($$v) {_vm.$set(field, "string", $$v)},expression:"field.string"}}):_vm._e(),(field.type == 'select')?_c(VSelect,{key:index,attrs:{"label":field.label,"items":field.options,"outlined":"","multiple":field.multiple,"clearable":""},on:{"input":function($event){return _vm.emitString()}},model:{value:(field.string),callback:function ($$v) {_vm.$set(field, "string", $$v)},expression:"field.string"}}):_vm._e(),(field.type == 'text-field')?_c(VTextField,{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.emitString),expression:"emitString",arg:"300ms"}],key:index,attrs:{"label":field.label,"outlined":""},model:{value:(field.string),callback:function ($$v) {_vm.$set(field, "string", $$v)},expression:"field.string"}}):_vm._e()]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }