import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"unset"}},[_c(VBtn,{attrs:{"text":"","color":"primary","icon":""},on:{"click":function($event){_vm.show = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-plus")])],1),_c(VDialog,{attrs:{"scrollable":"","width":"400px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_vm._v(_vm._s(_vm.$t("addBudget"))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VTextField,{attrs:{"type":"number","label":"Hoeveelheid","outlined":""},model:{value:(_vm.budget_amount),callback:function ($$v) {_vm.budget_amount=$$v},expression:"budget_amount"}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c(VBtn,{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("save")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }