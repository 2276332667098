import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"rounded":_vm.$config.shaped_widgets,"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"mb-0",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0"},[_vm._v(_vm._s(_vm.$t("requests")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right",attrs:{"x-large":""}},[_vm._v("mdi-clipboard-text")])],1),_c(VCardText,[_c(VDataTable,{staticClass:"adjusted-margin",attrs:{"headers":_vm.headers,"items":_vm.requests,"items-per-page":4,"sort-desc":"","sort-by":"created_at","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('a',{on:{"click":function($event){return _vm.$router.push('/requests/' + item.id)}}},[(_vm.$vuetify.breakpoint.md)?_c('small',[_vm._v(_vm._s(item.caregiver.last_name)+", "+_vm._s(item.caregiver.first_name))]):_c('small',[_vm._v(_vm._s(item.caregiver.last_name)+", "+_vm._s(item.caregiver.first_name))])])]}},{key:"item.status",fn:function({ item }){return [_c('small',{class:_vm.approvalStatus(item.approval_status).color + '--text'},[_vm._v(_vm._s(_vm.$t(_vm.approvalStatus(item.approval_status).text)))])]}},{key:"item.created_at",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD-MM-YYYY")))])]}}])})],1),_c(VFooter,{attrs:{"color":"#f1f1f1"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.showRequests()}}},[_vm._v(" "+_vm._s(_vm.$t("allRequests"))+" "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }