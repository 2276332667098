<template>
  <v-content>
    <v-toolbar class="wide-toolbar mb-3" color="transparent" flat>
      <v-btn text @click="$router.back()">
        <v-icon left>mdi-arrow-left</v-icon> {{ $t("back") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="save()" text color="primary">
        {{ $t("save") }}
        <v-icon right>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-row>
      <v-col cols="12">
        <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
          <v-card-title
            >Gegevens {{ participant.name }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-text-field
              :label="$t('firstName')"
              v-model="participant.firstName"
            ></v-text-field>
            <v-text-field
              :label="$t('lastName')"
              v-model="participant.lastName"
            ></v-text-field>

            <address-lookup
              @refresh="setAddress"
              :address="{
                postal_code: participant.zipCode,
                housenumber: participant.houseNumber,
                housenumber_addition: participant.suffix,
                street: participant.street,
                city: participant.city,
              }"
            ></address-lookup>
            <v-text-field
              :label="$t('email')"
              v-model="participant.email"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              :label="$t('card')"
              v-model="participant.accessToken"
              disabled
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <products :participant="participant"></products>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import AddressLookup from "../../components/AddressLookup.vue";
import Products from "./products/Index.vue";

export default {
  components: {
    Products,
    AddressLookup,
  },
  name: "Participant",
  data() {
    return {
      loading: false,
      show: false,
      participant_services: [],
      loading_address: false,
      required: (value) => !!value || this.$t("mandatory"),
      emailRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) ||
          this.$t("validEmail"),
      ],
      postcalCodeRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) ||
          this.$t("invalidPostalCode"),
      ],
      participant: {},
    };
  },
  created() {
    this.getParticipant();
    this.getParticipantServices();
  },
  methods: {
    setAddress(address) {
      this.participant.zipCode = address.postal_code;
      this.participant.houseNumber = address.housenumber;
      this.participant.suffix = address.housenumber_addition;
      this.participant.street = address.street;
      this.participant.city = address.city;
    },
    getParticipant() {
      this.participant = this.$store.getters.getParticipants.find(
        (s_request) => s_request.id == this.$route.params.id
      );
    },
    save() {
      this.loading = true;
      let participant = {
        city: this.participant.city,
        email: this.participant.email,
        external_id: this.participant.externalId,
        firstname: this.participant.firstName,
        housenumber: this.participant.houseNumber,
        id: this.participant.id,
        lastname: this.participant.lastName,
        street: this.participant.street,
        suffix: this.participant.suffix,
        zipcode: this.participant.zipCode,
      };
      this.$http
        .post(this.$config.backend_url + "/portaluser/update", participant)
        .then(() => {
          this.loading = false;
          this.$toast.success(this.$t("saved"));
        })
        .catch(() => {
          this.loading = false;
          this.$toast.success(this.$t("somethingWentWrong"));
        });
    },
    getParticipants() {
      this.loading = true;
      this.$http.get(this.$config.backend_url + "/users").then((response) => {
        this.$store.dispatch("setParticipants", response.data);
        this.getParticipant();
        this.loading = false;
      });
    },
  },
  computed: {
    address() {
      let street = this.participant.street ? this.participant.street : "-";
      let houseNumber = this.participant.houseNumber
        ? this.participant.houseNumber
        : "-";

      return street + " " + houseNumber;
    },
    postalCode() {
      let zipCode = this.participant.zipCode ? this.participant.zipCode : "-";
      let city = this.participant.city ? this.participant.city : "-";

      return zipCode + "  " + city;
    },
  },
};
</script>

<style lang="scss">
.uppercase input {
  text-transform: uppercase;
}
</style>
