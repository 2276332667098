<template>
  <v-card :rounded="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0">{{ $t("ValidationCheck") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right" x-large>mdi-account-check</v-icon>
    </v-card-title>

    <v-card-text>
      <v-row class="mx-2 mt-2">
        <v-col cols="12" sm="12" md="12">
          <v-text-field large hide-details :placeholder="$t('token')" v-model="token" :label="$t('validationToken')"
            outlined dense clearable></v-text-field>
          <v-btn class="mt-3" @click="validateToken" color="primary" block outlined>{{ $t("validate") }}</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="headline">{{ $t("validationResult") }}</v-card-title>
          <v-card-text>
            <!-- Loader -->
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <div v-else-if="is_minima" class="participant-info">
              <div class="participant-header">
                <h2>{{ response.participant.firstName }} {{ response.participant.preposition }} {{
                  response.participant.lastName }}
                </h2>
                <div v-for="(line, index) in formattedAddressLines" :key="index">
                  <p>{{ line }}</p>
                </div>
                <p>{{ formattedDate(response.participant.birthdate) }}</p>
              </div>

              <!-- Loop through all budgets -->
              <div v-if="$config.show_minima_validation_check" class="budgets-container">
                <template v-for="budget in response.budgets">
                  <div :key="budget.budgetId" class="budget-item">
                    <h3>{{ budget.budgetName }}</h3>
                    <p><strong>{{ $t('ValidFrom') }}</strong> {{ formattedDate(budget.validityStart) }}</p>
                    <p><strong>{{ $t('ValidUntil') }}</strong> {{ formattedDate(budget.validityEnd) }}</p>
                    <p><strong>{{ $t('Amount') }}</strong> {{ currency(budget.currentAmount) }}</p>
                  </div>
                </template>
              </div>
            </div>
            <div v-else>
              <div class="error-message">
                <p>{{ message }}</p>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <add-products-modal v-if="is_minima" @refresh="goToParticipant()" :participant="response.participant"
              :outlined="true" :small="true" color="primary" icon="mdi-plus"
              button_text="addProduct"></add-products-modal>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">{{ $t("close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-footer color="#f1f1f1">
      <v-spacer></v-spacer>
    </v-footer>
  </v-card>
</template>

<script>
import AddProductsModal from "@/components/AddProductsModalV2.vue";
export default {
  components: {
    AddProductsModal,
  },
  data() {
    return {
      dialog: false,
      token: null,
      message: null,
      is_minima: false,
      response: {
        participant: {
          firstName: "",
          preposition: "",
          lastName: "",
          birthdate: "",
          street: "",
          houseNumber: "",
          suffix: "",
          postalCode: "",
          city: "",
        },
        budgets: [],
      },
      items: [
        { text: this.$t("editAccount"), icon: "mdi-account", to: "profile" },
        { text: this.$t("setPassword"), icon: "mdi-account-key", to: "profile" },
      ],
      loading: false,
    };
  },
  methods: {
    goToParticipant() {
      this.$router.push('/caregivers/' + this.response.participant.id);
    },
    async validateToken() {
      this.loading = true;
      this.dialog = true;
      await this.$http
        .get(this.$config.backend_url + "/token-check/" + this.token)
        .then((response) => {
          this.message = response.data.message;
          this.is_minima = response.data.minima;
          this.response = response.data;
          this.response.participant.accessToken = this.token;
          this.loading = false;

        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    formattedDate(date) {
      if (!date) return "-";
      return new Date(date).toLocaleDateString("nl-NL");
    },
    currency(amount) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(amount);
    },
  },
  computed: {
    formattedAddressLines() {
      if (!this.response || !this.response.participant) return [];

      const { street, houseNumber, suffix, postalCode, city } = this.response.participant;
      let firstLine = [street, houseNumber].filter(part => part).join(' ');
      // postalCode should be in format 1111 AA
      const formattedPostalCode = postalCode.replace(/(\d{4})(\d{2})/, '$1 $2');
      if (suffix) firstLine += `-${suffix}`;
      let secondLine = [formattedPostalCode, city].filter(part => part).join(' ');

      return [firstLine, secondLine].filter(line => line);
    }
  },
};
</script>

<style scoped>
.participant-header h2 {
  margin-top: 15px;
  color: var(--v-primary);
}

.participant-header p {
  margin: 4px 0;
  font-size: 16px;
}

.budgets-container {
  margin-top: 20px;
}

.budget-item {
  border-radius: 15px;
  border: 1px solid #eee;
  padding: 10px;
  background-color: #eee;
}

.budget-item h3 {
  margin: 0;
  color: #424242;
  /* Dark grey */
}

.budget-item p {
  margin: 4px 0;
  font-size: 14px;
}

.error-message p {
  color: #D32F2F;
  /* Red for errors */
  font-size: 16px;
}
</style>
