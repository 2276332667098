import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBtn,{attrs:{"outlined":_vm.outlined,"small":_vm.small,"rounded":"","color":_vm.color},on:{"click":function($event){_vm.show = true}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.button_text))+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icon))])],1),_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"800","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[_c('strong',[_vm._v(" Producten toevoegen voor "+_vm._s(_vm.participant.lastName)+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"cardholder_form"},[_c('add-products',{attrs:{"participant":_vm.participant,"flat":"flat"},on:{"addedProduct":function($event){_vm.product_added = true},"refresh":_vm.refresh}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }