<template>
    <div>
        <v-expansion-panels :flat="flat" v-if="!loading" v-model="panel" accordion>
            <v-expansion-panel v-for="(service, i) in $lodash.sortBy(filterAvailableServices, 'name')" :key="i">
                <v-expansion-panel-header class="product-header">
                    <strong>{{ service.name }}</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table v-if="service.budgets.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t("name") }}</th>
                                    <th class="text-right">{{ $t("actions") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr @click="showAddProductModal(budget, service)" v-for="(budget, index) in sortArrayByName(service.budgets)" :key="index">
                                    <td class="px-3">{{ parseBudgetName(budget.name) }}</td>
                                    <td class="text-right px-3">


                                        <v-btn v-if="checkIfBudgetWasAdded(budget.id)" small icon color="primary" @click="showAddProductModal(budget, service)">
                                            <v-icon>mdi-checkbox-blank-outline</v-icon>
                                        </v-btn>
                                        <!-- show confirmation icon -->
                                        <v-btn v-else small icon color="success">
                                            <v-icon>mdi-checkbox-outline</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-alert v-else outlined transition border="left" type="info" dense class="mx-3">
                        {{ $t("noProductFound") }}
                    </v-alert>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="loading" class="text-center">
            <div class="ma-10">
                <v-progress-circular indeterminate color="primary" :size="80" :width="2" class="mb-5"></v-progress-circular>
                <p>{{ $t("loadingProducts") }}</p>
            </div>
        </div>

        <v-dialog hide-overlay v-model="show" :fullscreen="$vuetify.breakpoint.mdAndDown" max-width="500">
            <v-card dark color="primary">
                <v-toolbar flat color="transparent">
                    <strong class="mr-1">{{ $t("add") }}:</strong> {{ selected_service }} {{ new_budget.name }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field outlined class="hide-arrows" :label="$t('amount')" v-model="new_budget.amount" type="number" dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn x-large :loading="loading_add_product" block outlined @click="saveProduct(new_budget)">
                                {{ $t("add") }}
                                <v-icon right>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <EmailModal :show="show_email_modal" :participant="participant" @visible="show_email_modal = $event">
        </EmailModal>
    </div>
</template>

<script>
import EmailModal from './EmailModal.vue';

export default {
    props: ["participant", "flat", "filter"],
    components: {
        EmailModal,
    },
    data() {
        return {
            show: false,
            available_services: [],
            loading: false,
            new_budget: {
                amount: null,
                unit: null,
            },
            panel: null,
            loading_add_product: false,
            budgets_added: [],
            show_email_modal: false,
            selected_service: null,
        };
    },
    created() {
        this.getAvailableServices();
    },
    methods: {
        checkIfBudgetWasAdded(budget_id) {
            return !this.budgets_added.includes(budget_id);
        },
        sortArrayByName(array) {
            const sortedArray = [...array];
            return sortedArray.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        },
        parseBudgetName(name) {
            return name.charAt(0).toUpperCase() + name.slice(1).replace(/_/g, " ");
        },
        showAddProductModal(budget, service) {
            this.new_budget = budget;
            this.new_budget.amount = budget.default;
            this.show = true;
            this.selected_service = service.name;
        },
        async getAvailableServices() {
            this.loading = true;
            this.available_services = [];
            let cityid_programs = await this.getCityIDPograms();

            // Push cityid_programs to available_services
            cityid_programs.forEach((program) => {
                this.available_services.push(program);
            });

            this.loading = false;
        },
        async getCityIDPograms() {
            const response = await this.$http
                .get(this.$config.backend_url + "/provisioning/cityid")
                .then((response) => {
                    let programs = [];
                    response.data.provisionings.forEach((product) => {
                        let new_product = {
                            id: product.id,
                            name: product.name,
                            budgets: [],
                        };

                        let year = this.$config.mantelzorg_year
                            ? this.$config.mantelzorg_year
                            : new Date().getFullYear();

                        let budget_id = this.$config.care_budget_id;
                        let product_id = this.$config.care_provisioning_id;

                        product.budgets.forEach((budget) => {
                            if ((product_id && product.id == product_id) || product.name == "Mantelzorgwaardering") {
                                // Check either the budget period matches the year or the budget ID matches budget_id, budget_id first
                                if ((budget_id && budget.id == budget_id) || budget.period == year.toString()) {
                                    new_product.budgets.push({
                                        id: budget.id,
                                        name: budget.period,
                                        default: budget.defaultAmount,
                                        unit: "euro",
                                        portal_module: "cityid",
                                    });
                                }
                            } else if (
                                !(product_id && product.id == product_id) ||
                                product.name != "Mantelzorgwaardering"
                            ) {
                                new_product.budgets.push({
                                    id: budget.id,
                                    name: budget.period,
                                    default: budget.defaultAmount,
                                    unit: "euro",
                                    portal_module: "cityid",
                                });
                            }
                        });
                        programs.push(new_product);
                    });
                    return programs;
                })
                .catch(() => {
                    this.loading = false;
                });

            return response;
        },
        checkHasService(service_name) {
            let has_product = false;

            if (this.participant.services && this.participant.services.length > 0) {
                this.participant.services.forEach((service) => {
                    if (service_name == service.name) {
                        has_product = true;
                    }
                });
            }

            return has_product;
        },
        saveProduct(product) {
            this.loading_add_product = true;
            let endpoint = null;
            let body = null;

            if (product.portal_module === "cityid") {
                endpoint = "/provisioning/cityid/create";
                body = {
                    accesstoken: this.participant.accessToken,
                    budget_id: product.id,
                    amount: product.amount,
                };
            }

            if (!endpoint || !body) {
                this.loading_add_product = false;
                return this.$toast.error(this.$t("somethingWentWrong"));
            }

            this.$http
                .post(this.$config.backend_url + endpoint, body)
                .then(() => {
                    this.$toast.success(this.$t("saved"));
                    this.$emit("refresh");
                    this.show = false;
                    this.loading_add_product = false;
                    this.budgets_added.push(product.id);

                    if (product.id === this.$config.care_budget_id) {
                        this.show_email_modal = true;
                    } else {
                        this.$emit("addedProduct");
                    }
                })
                .catch((error) => {
                    this.loading_add_product = false;
                    const errorMessage = error.response?.data?.detail || this.$t("somethingWentWrong");

                    if (error.response) {
                        const statusCode = error.response.status;

                        if (statusCode >= 400 && statusCode < 500) {
                            this.$toast.error(this.$t(errorMessage) || this.$t("clientError"));
                        } else if (statusCode >= 500) {
                            this.$toast.error(this.$t(errorMessage) || this.$t("serverError"));
                        } else {
                            this.$toast.error(this.$t("unexpectedError"));
                        }
                    } else if (error.request) {
                        this.$toast.error(this.$t("noResponse"));
                    } else {
                        this.$toast.error(this.$t("requestError"));
                    }
                });
        },
    },
    computed: {
        filterAvailableServices() {
            if (!this.filter) {
                return this.available_services;
            }

            if (this.filter === "Mantelzorgwaardering") {
                return this.$config.care_provisioning_id
                    ? this.available_services.filter((service) => service.id === this.$config.care_provisioning_id)
                    : this.available_services.filter((service) => service.name === this.filter);
            }

            return this.available_services.filter((service) => service.name === this.filter);
        },
    },
    watch: {
        filterAvailableServices() {
            if (this.filterAvailableServices.length == 1) {
                this.panel = 0;
            } else {
                this.panel = null;
            }
        },
    },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
.hide-arrows input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.hide-arrows input[type="number"] {
    -moz-appearance: textfield;
}
</style>
