import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{style:(_vm.getBackground)},[_c(VAppBar,{attrs:{"dense":"","flat":"","app":"","color":"primary"}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.$config.return_url}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":"","color":"white"}},[_vm._v("mdi-open-in-new")]),_c('small',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t("backTo"))+" "+_vm._s(_vm.$config.name)+" ")])],1),_c(VSpacer),_c(VBtn,{attrs:{"title":"Contrast modus","dark":"","icon":""},on:{"click":_vm.setContrastMode}},[_c(VIcon,[_vm._v(" mdi-contrast-circle ")])],1)],1),_c(VMain,[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c('router-view')],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }