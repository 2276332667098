<template>
    <div>
        <v-row>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <v-card-title> Email templates </v-card-title>
                    <v-card-subtitle>{{
                        $t("Aanpassen van email teksten die gebruikt worden bij toekennen van voorzieningen")
                    }}</v-card-subtitle>
                    <v-card-text>
                        <v-data-table :headers="headers" :items="translations" :loading="loading" item-key="id">
                            <template v-slot:item.key="{ item }">
                                {{ item.key }}
                            </template>
                            <template v-slot:item.value="{ item }">
                                {{ item.value }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn @click="$router.push('/email-templates/' + item.id)" icon>
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            translations: [], // Array to store the translations
            loading: false,
            headers: [
                { text: "Key", value: "key" },
                { text: "Vertaling", value: "value" },
                {
                    text: this.$t("actions"),
                    align: "end",
                    sortable: true,
                    value: "actions",
                },
            ],
        };
    },
    created() {
        this.fetchTranslations();
    },
    methods: {
        async fetchTranslations() {
            try {
                this.loading = true;
                const response = await this.$http.get(this.$config.backend_url + "/care-request-mail/translations");
                this.translations = response.data;
                this.loading = false;
            } catch (error) {
                console.error("Error fetching translations:", error);
                this.loading = false;
            }
        },
    },
};
</script>
