import Vue from "vue";
import App from "./App.vue";
import config from "../config";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import { useFavicon, useTitle } from "@vueuse/core";
import Axios from "axios";
import VueMoment from "vue-moment";
import moment from "moment";
import VueCurrencyFilter from "vue-currency-filter";
import lodash from "lodash";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import DatePicker from "./components/DatePicker";
import VuetifyConfirm from "vuetify-confirm";
import vueDebounce from "vue-debounce";
import VueMask from "v-mask";

Vue.prototype.$config = config;

const icon = useFavicon();
icon.value = config.favicon_url;

const title = useTitle();
title.value = config.name;

Vue.use(vueDebounce);
Vue.use(VueMask);

Vue.use(VuetifyConfirm, {
    buttonTrueText: "Doorgaan",
    buttonFalseText: "Annuleren",
    color: "primary",
    icon: "mdi-alert",
    title: "Bevestigen",
    width: 350,
    property: "$confirm",
    vuetify,
});

const options = {
    position: "bottom-right",
};

Vue.use(Toast, options);
Vue.component("date-picker", DatePicker);

if (config.sentry_dsn) {
    Sentry.init({
        Vue,
        dsn: config.sentry_dsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", window.location.href, /^\//],
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

Vue.prototype.$lodash = lodash;

Vue.use(VueCurrencyFilter, {
    symbol: "€",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
});

Vue.prototype.$http = Axios;
moment.locale("nl");
Vue.use(VueMoment, { moment });

Vue.prototype.$http.interceptors.request.use(
    (config_axios) => {
        const accessToken = "Bearer " + localStorage.getItem("token");

        if (localStorage.getItem("token") !== null) {
            config_axios.headers.common.Authorization = accessToken;
            config_axios.headers.common.ContentType = "application/vnd.api+json";
            config_axios.headers.common.Accept = "application/vnd.api+json ";
            config_axios.headers["x-portal-instance-key"] = process.env.VUE_APP_INSTANCE_KEY;
        } else {
            delete config_axios.headers.common["Authorization"];
        }

        return config_axios;
    },
    (error) => {
        console.log(error);
        Promise.reject(error);
    }
);

Vue.prototype.$http.interceptors.response.use(null, (error) => {
    if (error.response && error.response.status === 403) {
        router.push("/403");
    }

    if (error.response && error.response.status === 401) {
        store.dispatch("logout");
    }

    if (typeof error.response === "undefined") {
        router.push("/offline");
    }

    return Promise.reject(error);
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
