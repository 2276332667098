<template>
    <v-dialog v-model="show_email_dialog" hide-overlay :fullscreen="$vuetify.breakpoint.mdAndDown" max-width="600">
        <v-card>
            <v-toolbar flat color="transparent">
                <strong>
                    Bevestigingsmail
                </strong>
                <v-spacer></v-spacer>
                <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="confirm_submit_form">
                    <vue-editor :editorToolbar="customToolbar" v-model="confirmation_email">
                    </vue-editor>
                </v-form>
            </v-card-text>
            <!-- footer with button for sending email -->
            <v-footer class="mt-5">
                <v-btn rounded block outlined color="primary" @click="sendMail()">
                    {{ $t("saveAndSendEmail") }}
                </v-btn>
                <strong class="mx-auto my-5">OF</strong>
                <v-btn rounded block outlined color="primary" @click="show = false">
                    Sluiten
                </v-btn>
            </v-footer>
        </v-card>
    </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
    components: { VueEditor },
    props: [
        'participant',
        'show'
    ],
    data() {
        return {
            show_email_dialog: false,
            product_added: false,
            loading: false,
            valid: true,
            step: 1,
            confirmation_email: "",
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                ["orderedlist", "unorderedlist"],
                ["link", "image"],
                ["removeFormat"],
            ],
        };
    },
    created() {
        this.confirmation_email = this.$t("addedProductsEmailBody").replace(
            /<div/g,
            "<p"
        );
    },
    methods: {
        sendMail() {
            let email = {
                email: this.participant.email,
                body: this.confirmation_email,
                subject: this.$t("addedProductsEmailSubject"),
            };
            this.$http
                .post(this.$config.backend_url + "/care-request-mail", email)
                .then(() => {
                    this.loading = false;
                    this.show = false;
                    this.$toast.success(this.$t("saved"));
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.error(this.$t("somethingWentWrong"));
                });
        },
    },
    watch: {
        show(val) {
            this.show_email_dialog = val;
            this.$emit('visible', val);
        },
    },
};
</script>
<style lang="">

</style>
