<template>
    <div>
        <v-row>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <v-card-title> Email template aanpassen </v-card-title>
                    <v-card-subtitle>
                        {{ $t("Pas hier de tekst aan, let op: verwijder de html code niet") }}
                    </v-card-subtitle>
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="saveTranslation">
                            <v-text-field label="Key" v-model="translation.key" readonly></v-text-field>
                            <v-textarea label="Vertaling" v-model="translation.value" outlined rows="12"></v-textarea>
                            <v-btn color="primary" type="submit">Save</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            translation: {
                key: "",
                value: "",
            },
        };
    },
    created() {
        this.fetchTranslation();
    },
    methods: {
        async fetchTranslation() {
            try {
                const response = await this.$http.get(
                    this.$config.backend_url + "/care-request-mail/translations/" + this.$route.params.id
                );
                this.translation = response.data;
            } catch (error) {
                console.error("Error fetching translation:", error);
            }
        },
        async saveTranslation() {
            try {
                await this.$http
                    .patch(this.$config.backend_url + "/care-request-mail/translations/" + this.$route.params.id, {
                        value: this.translation.value,
                    })
                    .then(() => {
                        this.$toast.success(this.$t("saved"));
                    });
            } catch (error) {
                console.error("Error saving translation:", error);
                this.$toast.success(this.$t("somethingWentWrong"));
            }
        },
    },
};
</script>
