<template>
  <v-content>
    <v-toolbar class="wide-toolbar mb-3" color="transparent" flat>
      <v-btn text @click="$router.back()">
        <v-icon left>mdi-arrow-left</v-icon> {{ $t("back") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="$router.push('/caregivers/edit/' + participant.id)"
        text
        color="primary"
      >
        {{ $t("edit") }}
        <v-icon right>mdi-grease-pencil</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
          <v-card-title
            >Gegevens
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <field-row
              :label="$t('firstName')"
              :value="participant.firstName"
            ></field-row>
            <field-row
              :label="$t('lastName')"
              :value="participant.lastName"
            ></field-row>
            <field-row :label="$t('address')" :value="address"></field-row>
            <field-row
              :label="$t('postalCodeAndCity')"
              :value="postalCode.toUpperCase()"
            ></field-row>
            <field-row
              :label="$t('card')"
              :value="participant.accessToken"
            ></field-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <products :participant="participant"></products>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import FieldRow from "../../components/FieldRow.vue";
import Products from "./products/Index.vue";

export default {
  components: {
    FieldRow,
    Products,
  },
  name: "Participant",
  data() {
    return {
      loading: false,
      show: false,
    };
  },
  methods: {},
  computed: {
    participant() {
      return this.$store.getters.getParticipants.find(
        (s_request) => s_request.id == this.$route.params.id
      );
    },
    address() {
      let street = this.participant.street ? this.participant.street : "-";
      let houseNumber = this.participant.houseNumber
        ? this.participant.houseNumber
        : "-";
      let suffix = this.participant.suffix ? this.participant.suffix : "";

      return street + " " + houseNumber + " " + suffix;
    },
    postalCode() {
      let zipCode = this.participant.zipCode ? this.participant.zipCode : "-";
      let city = this.participant.city ? this.participant.city : "-";

      return zipCode + "  " + city;
    },
  },
};
</script>

<style lang="scss"></style>
