import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-lg-5",attrs:{"rounded":_vm.$config.shaped_widgets}},[_c(VCardTitle,[_vm._v(" Email templates ")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.$t("Aanpassen van email teksten die gebruikt worden bij toekennen van voorzieningen")))]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.translations,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.key",fn:function({ item }){return [_vm._v(" "+_vm._s(item.key)+" ")]}},{key:"item.value",fn:function({ item }){return [_vm._v(" "+_vm._s(item.value)+" ")]}},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push('/email-templates/' + item.id)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }