<template>
    <div>
        <v-row>
            <v-col>
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <v-card-title>
                        <v-text-field
                            full-width
                            rounded
                            v-model="search"
                            :label="$t('searchRequests')"
                            prepend-inner-icon="mdi-magnify"
                            filled
                            dense
                            clearable
                            hide-details
                        ></v-text-field>

                        <v-spacer></v-spacer>
                        <filter-modal @refresh="handleFilterChange" :options="filter_options"></filter-modal>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-switch
                            v-if="$vuetify.breakpoint.lgAndUp"
                            v-model="showExtraColumns"
                            :label="$t('Toon extra gegevens')"
                        ></v-switch>
                    </v-card-subtitle>
                    <v-card-text>
                        <div class="table-scroll">
                            <v-data-table
                                :loading="loading"
                                :headers="visibleHeaders"
                                :items="filtered"
                                item-key="id"
                                :show-select="showSelect && !isBulkProcessing"
                                v-model="selected"
                                sort-desc
                                sort-by="created_at"
                                :options.sync="options"
                                :items-per-page.sync="options.itemsPerPage"
                                :footer-props="footer_props"
                                :item-class="rowClass"
                                :class="{ 'wide-table': showExtraColumns }"
                                @update:items-per-page="(itemsPerPage) => updateVuexItemsPerPage(itemsPerPage)"
                            >
                                <template v-slot:item.created_at="{ item }">
                                    {{ item.created_at | moment("DD-MM-YYYY") }}
                                </template>
                                <template v-slot:item.name="{ item }">
                                    {{ item.caregiver.last_name }}, {{ item.caregiver.first_name }}
                                </template>

                                <template v-slot:item.postal_code="{ item }">
                                    {{ item.caregiver.postal_code.toUpperCase() }}
                                </template>

                                <template v-slot:item.approval_status="{ item }">
                                    <v-chip :color="approvalStatus(item.approval_status).color" small>{{
                                        approvalStatus(item.approval_status).text
                                    }}</v-chip>
                                </template>

                                <template v-slot:item.extension="{ item }">
                                    <v-chip :color="item.extension ? 'info' : 'none'" small>{{
                                        item.extension ? $t("Verlenging") : $t("Nieuw")
                                    }}</v-chip>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-btn @click="$router.push('/requests/' + item.id)" icon>
                                        <v-icon small>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </div>
                        <v-card-text>
                            {{ $t("Geselecteerde aanvragen:") }} {{ selected.length }} {{ $t("van de maximaal") }}
                            {{ maxRows }}</v-card-text
                        >
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Button for approval -->
        <div class="text-left pa-3">
            <v-btn color="primary" @click="showDialog = true" :disabled="isBulkProcessing || selected.length === 0">
                {{ $t("Bulk goedkeuren") }}
            </v-btn>

            <v-tooltip bottom v-if="isBulkProcessing || selected.length === 0">
                <template v-slot:activator="{ on }">
                    <span v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                    </span>
                </template>
                <span v-if="isBulkProcessing">{{ $t("Er worden op dit moment al bulk aanvragen verwerkt...") }}</span>
                <span v-if="selected.length === 0">{{ $t("Selecteer eerst aanvragen...") }}</span>
            </v-tooltip>
        </div>

        <!-- Confirmation Dialog for the carerequests -->
        <v-dialog v-model="showDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">{{ $t("Bevestiging") }}</v-card-title>
                <v-card-text>
                    {{ $t("Weet je zeker dat je de geselecteerde aanvragen wil goedkeuren?") }}
                    <br />
                    <strong>
                        {{ $t("Je hebt") }} {{ countExtensions }}
                        {{ countExtensions === 1 ? $t("verlenging") : $t("verlengingen") }} {{ $t("en") }}
                        {{ countNewRequests }}
                        {{ countNewRequests === 1 ? $t("nieuwe aanvraag") : $t("nieuwe aanvragen") }}
                        {{ $t("geselecteerd") }}.
                    </strong>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="approveSelected">{{ $t("Alle goedkeuren") }}</v-btn>
                    <v-btn color="red darken-1" text @click="showDialog = false">{{ $t("Annuleren") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FilterModal from "../../components/FilterModal.vue";
import Fuse from "fuse.js";
export default {
    components: { FilterModal },
    name: "Requests",
    data() {
        return {
            search: "",
            open: false,
            denied: false,
            approved: false,
            loading: false,
            headers: [
                {
                    text: this.$t("date"),
                    align: "start",
                    sortable: true,
                    value: "created_at",
                },
                {
                    text: this.$t("firstName"),
                    align: "start",
                    sortable: true,
                    value: "caregiver.first_name",
                },
                {
                    text: this.$t("lastName"),
                    align: "start",
                    sortable: true,
                    value: "caregiver.last_name",
                },
                {
                    text: this.$t("postalCode"),
                    align: "start",
                    sortable: true,
                    value: "caregiver.postal_code",
                },
                {
                    text: this.$t("approvalStatus"),
                    align: "start",
                    sortable: true,
                    value: "approval_status",
                },
                {
                    text: this.$t("Type aanvraag"),
                    align: "start",
                    sortable: true,
                    value: "extension",
                },
                {
                    text: this.$t("Relatie"),
                    align: "start",
                    sortable: false,
                    value: "relationship",
                },
                {
                    text: this.$t("Zorgtaken"),
                    align: "start",
                    sortable: false,
                    value: "caregiver_duties",
                },
                {
                    text: this.$t("Ziektebeeld"),
                    align: "start",
                    sortable: false,
                    value: "carereceiver_clinical_picture",
                },
                {
                    text: this.$t("Uren"),
                    align: "start",
                    sortable: true,
                    value: "hours_per_week",
                },
                {
                    text: this.$t("card"),
                    align: "start",
                    sortable: true,
                    value: "accesstoken",
                },
                {
                    text: this.$t("actions"),
                    align: "end",
                    sortable: true,
                    value: "actions",
                },
            ],
            requests: [],
            filter_options: [
                {
                    name: "approval-filter",
                    label: this.$t("approvalStatus"),
                    type: "select",
                    multiple: true,
                    string: ["OPEN"],
                    options: [
                        {
                            text: this.$t("open"),
                            value: "OPEN",
                        },
                        {
                            text: this.$t("In behandeling"),
                            value: "IN_PROGRESS",
                        },
                        {
                            text: this.$t("approved"),
                            value: "APPROVED",
                        },
                        {
                            text: this.$t("disapproved"),
                            value: "DISAPPROVED",
                        },
                        {
                            text: this.$t("Foutief"),
                            value: "FAILED",
                        },
                    ],
                },
                {
                    name: "extension-filter",
                    label: this.$t("Type aanvraag"),
                    type: "select",
                    multiple: true,
                    string: ["true", "false"],
                    options: [
                        { text: this.$t("Verlenging"), value: "true" },
                        { text: this.$t("Nieuw"), value: "false" },
                    ],
                },
            ],
            options: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 25,
                multiSort: false,
                mustSort: true,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            footer_props: {
                itemsPerPageOptions: [5, 15, 25, 50, 100, 150],
                showFirstLastPage: true,
            },
            selected: [],
            showDialog: false,
            isBulkProcessing: false,
            pollingInterval: null,
            currentFilterString: "",
            maxSelectionReached: false,
            maxRows: 100,
            showExtraColumns: false,
        };
    },
    created() {
        if (this.$route.params.approval_status) {
            let option = this.filter_options.find((option) => option.name == "approval-filter");

            option.string = [this.$route.params.approval_status];
        }
        this.checkJobStatus();
    },
    mounted() {
        this.getFilterSettings();
    },
    beforeDestroy() {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    },
    methods: {
        getRequests() {
            this.loading = true;
            let filterString = `?itemsPerPage=${this.options.itemsPerPage}&page=${this.options.page}`;

            const { approvalFilter, extensionFilter } = this.$store.state.requestIndexSettings;
            if (approvalFilter.length) {
                filterString += `&approval-filter=${approvalFilter.join(",")}`;
            }
            if (extensionFilter.length) {
                filterString += `&extension-filter=${extensionFilter.join(",")}`;
            }

            this.$http
                .get(`${this.$config.backend_url}/care-requests${filterString}`)
                .then((response) => {
                    this.requests = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error("Failed to fetch requests:", error);
                    this.loading = false;
                });
        },
        async getFilterSettings() {
            const storeSettings = this.$store.state.requestIndexSettings;
            this.search = storeSettings.search;
            this.options.itemsPerPage = storeSettings.itemsPerPage;
            this.options.page = storeSettings.page;
            await this.applyStoredFilters(storeSettings);
        },
        async handleFilterChange(filterString = "") {
            const params = new URLSearchParams(filterString);

            // Define approvalFilter and extensionFilter by checking if params have them,
            // fallback to Vuex state if not present in params.
            let approvalFilter = params.get("approval-filter")
                ? params.get("approval-filter").split(",")
                : this.$store.state.requestIndexSettings.approvalFilter;

            // Since we ensure extensionFilter always has values, no need for null check
            let extensionFilter = params.get("extension-filter")
                ? params.get("extension-filter").split(",")
                : ["true", "false"]; // Default to both options if not specified

            // Dispatch the Vuex action to update the store based on either the new filters or existing Vuex state.
            await this.$store.dispatch("requestIndexSettings/updateSettings", {
                search: this.search,
                itemsPerPage: this.options.itemsPerPage,
                page: this.options.page,
                approvalFilter,
                extensionFilter,
            });

            // Refetch requests with the updated filter settings.
            this.getRequests();
        },
        updateVuexStoreWithFilterString(filterString) {
            const params = new URLSearchParams(filterString);

            return this.$store.dispatch("requestIndexSettings/updateSettings", {
                search: this.search,
                itemsPerPage: this.options.itemsPerPage,
                page: this.options.page,
                approvalFilter: this.$store.state.requestIndexSettings.approvalFilter,
                extensionFilter: params.get("extension-filter") ? params.get("extension-filter").split(",") : [],
            });
        },
        async applyStoredFilters(storeSettings) {
            // Ensure this method waits for any async operations before proceeding.
            const approvalFilterOption = this.filter_options.find((option) => option.name === "approval-filter");
            if (approvalFilterOption) approvalFilterOption.string = storeSettings.approvalFilter;

            const extensionFilterOption = this.filter_options.find((option) => option.name === "extension-filter");
            if (extensionFilterOption) extensionFilterOption.string = storeSettings.extensionFilter;

            await this.handleFilterChange(); // Already awaits updateVuexStoreWithFilterString
        },

        updateVuexItemsPerPage(itemsPerPage) {
            this.$store.commit("requestIndexSettings/SET_ITEMS_PER_PAGE", itemsPerPage);
        },
        filter(value, search) {
            return (
                value != null &&
                search != null &&
                typeof value === "string" &&
                value.toString().toLowerCase().indexOf(search) !== -1
            );
        },
        approvalStatus(status) {
            if (status == "APPROVED") {
                return {
                    text: this.$t("approved"),
                    color: "success",
                };
            }
            if (status == "DISAPPROVED") {
                return {
                    text: this.$t("disapproved"),
                    color: "error",
                };
            }
            if (status == "IN_PROGRESS") {
                return {
                    text: this.$t("In behandeling"),
                    color: null,
                };
            }

            return {
                text: this.$t("open"),
                color: null,
            };
        },
        getSelectedIds() {
            return this.selected.map((item) => item.id);
        },
        approveSelected() {
            this.isBulkProcessing = true;
            const selectedIds = this.getSelectedIds();

            // Prepare the request payload
            const payload = {
                informal_care_request_ids: selectedIds,
            };

            let url = process.env.VUE_APP_BACKEND_URL + "/bulk/provisioning/care-budget";
            // Make the API call
            this.$http
                .post(url, payload, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    // Handle success response
                    console.log("Job dispatched:", response.data);
                    this.showDialog = false; // Close the dialog
                    this.checkJobStatus();
                })
                .catch((error) => {
                    // Handle error
                    console.error("Error dispatching job:", error);
                    this.showDialog = false; // Close the dialog
                    this.isBulkProcessing = false;
                });
        },
        checkJobStatus() {
            let url = process.env.VUE_APP_BACKEND_URL + "/bulk/provisioning/care-budget/status";
            this.$http
                .get(url, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    const status = response.data.status;
                    if (status === "pending" || status === "processing") {
                        this.isBulkProcessing = true; // A job is already running
                        // Start polling
                        this.startPolling();
                    } else {
                        this.isBulkProcessing = false; // No job running or job is completed/failed
                        this.selected = []; // Clear the selected items
                    }
                })
                .catch((error) => {
                    console.error("Error fetching job status:", error);
                    this.isBulkProcessing = false;
                });
        },
        startPolling() {
            // Start polling every 5 seconds
            this.pollingInterval = setInterval(() => {
                this.pollJobStatus();
            }, 5000);
        },
        pollJobStatus() {
            let url = process.env.VUE_APP_BACKEND_URL + "/bulk/provisioning/care-budget/status";
            this.$http
                .get(url, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    const status = response.data.status;
                    if (status === "completed" || status === "failed" || status === "no jobs") {
                        this.isBulkProcessing = false; // Job is done, enable UI elements
                        this.selected = []; // Clear the selected items
                        clearInterval(this.pollingInterval); // Stop polling
                        this.getRequests(this.currentFilterString); // Refresh the data
                    }
                })
                .catch((error) => {
                    console.error("Error fetching job status:", error);
                    this.isBulkProcessing = false;
                    clearInterval(this.pollingInterval); // Stop polling on error
                    this.getRequests(this.currentFilterString); // Refresh the data
                });
        },
        rowClass(item) {
            if (this.maxSelectionReached && !this.selected.includes(item)) {
                return "disabled-row"; // This class will indicate that the row is disabled
            }
            return "";
        },
    },
    computed: {
        filtered() {
            // Define Fuse.js options
            const options = {
                includeScore: true,
                keys: [
                    "caregiver.last_name",
                    "caregiver.first_name",
                    "caregiver.postal_code",
                    "approval_status",
                    "accesstoken",
                ],
                threshold: 0.3,
            };

            // Initialize Fuse.js with your requests data
            const fuse = new Fuse(this.requests, options);

            // Perform the search using Fuse.js if there's a search query
            let result = this.search ? fuse.search(this.search).map((item) => item.item) : this.requests;

            // Filter for the approval status
            const approvalFilter = this.filter_options.find((o) => o.name === "approval-filter").string;
            if (approvalFilter.length > 0) {
                result = result.filter((item) => approvalFilter.includes(item.approval_status));
            }

            // Filter for the extension value
            const extensionFilter = this.filter_options.find((o) => o.name === "extension-filter").string;
            if (extensionFilter.length > 0) {
                result = result.filter((item) => extensionFilter.includes(item.extension.toString()));
            }

            // Return the filtered (and searched) result
            return result;
        },
        showSelect() {
            const approvalFilter = this.filter_options.find((option) => option.name === "approval-filter");
            // Check if the approval filter is exactly 'OPEN'
            return approvalFilter && approvalFilter.string.length === 1 && approvalFilter.string[0] === "OPEN";
        },
        countExtensions() {
            return this.selected.filter((item) => item.extension === true).length;
        },

        // Count of selected new requests
        countNewRequests() {
            return this.selected.filter((item) => item.extension === false).length;
        },
        visibleHeaders() {
            if (this.showExtraColumns) {
                console.log("Showing extra columns");
                return this.headers;
            } else {
                // Return headers excluding those that should be hidden when showExtraColumns is false
                return this.headers.filter(
                    (header) =>
                        header.value !== "relationship" &&
                        header.value !== "caregiver_duties" &&
                        header.value !== "carereceiver_clinical_picture" &&
                        header.value !== "hours_per_week"
                );
            }
        },
    },
    watch: {
        selected(newVal) {
            if (newVal.length > this.maxRows) {
                this.selected = newVal.slice(0, this.maxRows);
            }

            this.maxSelectionReached = newVal.length >= this.maxRows;
        },
    },
};
</script>

<style lang="scss">
.disabled-row {
    opacity: 0.6;
    pointer-events: none; // Prevents interactions
}

.table-scroll {
    overflow-x: auto;
    max-width: 100%; /* Adjust this as needed */
}

.wide-table {
    min-width: 1600px;
}
</style>
