<template>
    <v-card :rounded="$config.shaped_widgets" :loading="loading" class="widget">
        <v-card-title class="mb-0" flat>
            <v-card-title class="mb-0 py-0">{{ $t("requests") }}</v-card-title>
            <v-spacer></v-spacer>
            <v-icon class="align-right" x-large>mdi-clipboard-text</v-icon>
        </v-card-title>

        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="requests"
                :items-per-page="4"
                sort-desc
                sort-by="created_at"
                hide-default-footer
                dense
                class="adjusted-margin"
            >
                <template v-slot:item.name="{ item }">
                    <a @click="$router.push('/requests/' + item.id)">
                        <small v-if="$vuetify.breakpoint.md"
                            >{{ item.caregiver.last_name }}, {{ item.caregiver.first_name }}</small
                        >
                        <small v-else>{{ item.caregiver.last_name }}, {{ item.caregiver.first_name }}</small>
                    </a>
                </template>

                <template v-slot:item.status="{ item }">
                    <small :class="approvalStatus(item.approval_status).color + '--text'">{{
                        $t(approvalStatus(item.approval_status).text)
                    }}</small>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <small> {{ item.created_at | moment("DD-MM-YYYY") }}</small>
                </template>
            </v-data-table>
        </v-card-text>
        <v-footer color="#f1f1f1">
            <v-spacer></v-spacer>
            <v-btn text small @click="showRequests()">
                {{ $t("allRequests") }}
                <v-icon right small>mdi-arrow-right</v-icon>
            </v-btn>
        </v-footer>
    </v-card>
</template>

<script>
export default {
    components: {},
    name: "Transactions",
    data() {
        return {
            loading: false,
            headers: [
                {
                    text: "Deelnemer",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                {
                    text: "Status",
                    sortable: false,
                    value: "status",
                    align: "start",
                },
                {
                    text: "Datum",
                    align: "end",
                    sortable: false,
                    value: "created_at",
                },
            ],
            requests: [],
        };
    },
    created() {
        this.getRequests();
    },
    methods: {
        getRequests() {
            this.loading = true;
            this.$http.get(this.$config.backend_url + "/care-requests").then((response) => {
                this.requests = response.data;
                this.loading = false;
            });
        },
        async showRequests() {
            await this.$store.dispatch("requestIndexSettings/updateSettings", {
                // Assuming you want to keep other settings as they are, you might need to pass them along too
                search: this.$store.state.requestIndexSettings.search,
                itemsPerPage: this.$store.state.requestIndexSettings.itemsPerPage,
                page: this.$store.state.requestIndexSettings.page,
                approvalFilter: ["OPEN"], // Set the new approval status
                extensionFilter: this.$store.state.requestIndexSettings.extensionFilter, // Keep existing
            });
            this.$router.push("/requests");
        },
        approvalStatus(status) {
            if (status == "APPROVED") {
                return {
                    text: this.$t("approved"),
                    color: "success",
                };
            }
            if (status == "DISAPPROVED") {
                return {
                    text: this.$t("disapproved"),
                    color: "error",
                };
            }

            return {
                text: this.$t("open"),
                color: null,
            };
        },
    },
    computed: {},
};
</script>

<style lang="scss"></style>
